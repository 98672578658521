@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "components/formFields";
@import "custom/variables/variables";
@import "custom/utils/mixins/mixins";
@import "custom/utils/placeholders/placeholders";
@import "custom/components/buttons";
@import "custom/components/inputs";
@import "custom/components/controls";

.page {
    background-color: $color-neutral10;
}

#maincontent {
    min-height: calc(100vh - 500px);
}

.login-page,
.checkout-login {
    margin-top: 80px;

    @include media-breakpoint-down(md) {
        margin-top: 30px;
    }

    .buttons-container {
        @include media-breakpoint-down(sm) {
            min-height: 55vh;
        }
    }

    .card {
        background-color: $color-white;
        border-color: $color-white;
        border-radius: $border-radius-base;

        @include media-breakpoint-down(sm) {
            margin-bottom: 5px;
        }
    }

    .card-body {
        padding: 0px 48px;

        @include media-breakpoint-down(sm) {
            padding: 0px 10px;
        }

        .login-form-nav {
            .nav-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                font-family: $font-qualion;
                font-weight: 700;
                font-size: $title-01--desktop;
                color: $color-darkGreen;

                &.active {
                    & > svg {
                        transform: rotate(180deg);
                    }
                }

                .login-link {
                    padding: 10px 0px;
                    color: inherit;
                    font-weight: bold;
                    text-decoration: none;
                }
            }

            .tab-content {
                display: none;

                &.tab-login {
                    display: block;
                }
            }
        }

        .login-oauth {
            margin-top: 20px;

            .form-group {
                margin: 0;

                a {
                    padding: 22px 0;

                    @include media-breakpoint-down(sm) {
                        padding: 14px 0;
                    }
                }

                .oauth-facebook {
                    margin-bottom: 10px;
                }

                .social-links {
                    width: 100%;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        font-family: $font-qualion;
                        font-weight: 700;
                        font-size: $title-01--desktop;
                        color: $color-darkGreen;

                        @include media-breakpoint-down(sm) {
                            font-size: $title-01--mobile;
                        }
                    }

                    &:hover {
                        span {
                            color: $color-lightGreen;
                        }
                    }
                }
            }
        }

        .enter-data-text {
            margin: 21px 0px;
            text-align: center;
            font-family: $font-qualion;
            font-size: $text-01--desktop;
        }

        .remember-me,
        .policyconfirm,
        .newsletterconfirm {
            display: flex;
            margin: 10px 0px;

            .checkbox-container {
                padding-left: 26px;

                span {
                    top: 4px;
                    left: 0;
                    height: 16px;
                    width: 16px;

                    &::after {
                        left: 4px;
                        top: 1px;
                        width: 5px;
                        height: 8px;
                    }
                }
            }

            .remember-me-text {
                color: $color-darkGreen80;
                font-size: $title-00--desktop;
                font-family: $font-qualion;
            }
        }

        .btn-primary {
            width: 100%;
            font-family: $font-qualion;
            font-weight: 700;
            font-size: $title-01--desktop;

            @include media-breakpoint-down(sm) {
                font-size: $text-02--desktop;
            }
        }

        .forgot-password {
            margin-top: 13px;

            #password-reset {
                color: $color-darkGreen;
                font-size: $text-01--desktop;
                font-family: $font-qualion;
                font-weight: 700;
            }
        }

        .login {
            margin-bottom: 0.938em;

            .form-group .error-message-form {
                padding: 0.625em;
                background-color: $danger;
                color: $state-danger-text;
                border-radius: 0.188em;
            }
        }

        .user-password {
            position: relative;

            input {
                padding-right: 35px;
            }

            svg {
                position: absolute;
                right: 7px;
                top: 12px;
                cursor: pointer;
                color: $color-neutral50;
            }
        }

        .modal-dialog {
            top: 50%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);

            @include media-breakpoint-down(sm) {
                position: fixed;
                top: auto;
                width: 100%;
                bottom: 0;
                transform: translate(-50%, 0);
            }

            .modal-content {
                border-radius: 16px;
                padding: 35px 20px 20px;

                @include media-breakpoint-down(sm) {
                    padding: 20px 0px 0px;
                }

                .modal-close-button {
                    position: relative;

                    .request-password-title {
                        font-family: $font-qualion;
                        font-size: $title-02--desktop;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }

                    button {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        outline: none;

                        @include media-breakpoint-down(sm) {
                            bottom: -5px;
                        }

                        svg {
                            width: 18px;
                            height: 18px;
                            color: $color-black;
                        }
                    }
                }

                .confirm-reset-icon {
                    display: none;
                    text-align: center;
                }

                .request-password-body {
                    p {
                        font-family: $font-qualion;
                        font-size: $text-02--desktop;
                        margin: 0;
                    }

                    .reset-confirm {
                        margin-bottom: 24px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .login-page_return {
        display: flex;
        align-items: center;
        margin: 10px 0px 50px;
        font-family: $font-qualion;
        font-size: $text-02--desktop;
        color: $color-darkGreen;

        @include media-breakpoint-down(sm) {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin: 10px 0px 30px;
        }

        svg {
            margin: 0px 10px 3px 0px;
            width: 12px;
            height: 12px;
        }

        button {
            line-height: 0;
            height: 24px;
            padding: 0;

            &:focus{
                box-shadow: none;
            }
        }

        &:hover {
            cursor: pointer;

            > * {
                color: $color-lightGreen;
            }
        }
    }

    .registration {
        margin-bottom: 40px;
        color: $color-darkGreen80;

        .login-account-policy, .login-account-newsletter {
            font-family: $font-qualion;
            font-size: $text-02--desktop;
            margin-bottom: 20px;

            .login-policy {
                color: $color-darkGreen80;
                text-decoration: underline;
            }

            &.color-error {
                color: map-get($colors, "error");

                & > .login-policy  {
                    color: map-get($colors, "error");
                }
            }
        }
    }
}

.checkout-login {
    .card-body {
        .enter-data-text {
            margin: 24px 0px;
            text-align: center;
            font-family: $font-qualion;
            color: $color-neutral40v2;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            gap: 12px;

            .enter-data-text-slash {
                width: 100px;
                border-top: 1px solid #D9E6E2;
            }
        }

        .customer-login-label {
            color: $color-neutral80;
            font-family: $font-qualion;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        .submit-customer-login {
            height: 44px;
            padding: 10px 16px;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
        }

        .login-oauth {
            .form-group {
                .social-links {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    padding: 12px 24px;
                    border-radius: 8px;
                    border: 1px solid #D0D5DD;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    text-transform: none;
                    height: 48px;

                    span {
                        font-family: $font-qualion;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $color-neutral80;
                    }

                    svg {
                        width: 32px;
                        height: 32px;
                    }

                    &:hover {
                        span {
                            color: $color-neutral80;
                        }
                    }
                }
            }
        }

        .forgot-password {
            #password-reset {
                color: $color-darkGreen70;
                font-size: 12px;
                font-family: $font-qualion;
                font-weight: 600;
                line-height: 24px;
                text-decoration: none !important;
                border-bottom: 1px solid $color-darkGreen70;
            }
        }
    }
}

.login-banner {
    background-image: url('../images/account.jpg');
    background-position-y: 40%;
}

.equal-height .card {
    width: 100%;
}

.track-order-header {
    font-size: 1.75rem;
}

.request-password-title {
    font-size: 1.5rem;
}

.new-password-page {
    margin-top: 80px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 33%;
    max-width: 466px;

    @include media-breakpoint-down(md) {
        margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
        min-height: inherit;
        width: 100%;
    }

    form {
        width: 100%;

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .form-content {
            width: 100%;
            border-radius: 5px;
            background-color: $color-white;
            padding: 24px 48px;
            margin-bottom: 24px;

            @include media-breakpoint-down(sm) {
                padding: 24px 16px;
            }

            .form-title {
                font-size: $text-04--mobile;
                line-height: 30px;
                color: $color-darkGreen;
                font-weight: 700;
                font-family: $font-qualion;
                margin-bottom: 16px;
            }

            .form-group {
                font-family: $font-qualion;

                &:first-child {
                    margin-bottom: 8px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .buttons-wrapper {
            width: 100%;
            margin-bottom: 16px;

            @include media-breakpoint-down(sm) {
                padding-left: 16px;
                padding-right: 16px;
            }

            button {
                width: 100%;
                max-width: calc(50% - 12px);

                &:first-child {
                    margin-left: 24px;
                }

                @include media-breakpoint-down(sm) {
                    height: 44px;
                    max-width: 100%;

                    &:first-child {
                        margin-bottom: 8px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.customer-guest-shipping {
    .policyconfirm,
    .newsletterconfirm {
        display: flex;
        margin: 16px 0px;

        .checkbox-container {
            padding-left: 26px;

            span {
                top: 4px;
                left: 0;
                height: 16px;
                width: 16px;

                &::after {
                    left: 4px;
                    top: 1px;
                    width: 5px;
                    height: 8px;
                }
            }
        }

        .login-account-newsletter,
        .login-account-policy {
            color: $color-neutral80;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .login-account-policy {
        color: $color-neutral70;
        font-family: $font-qualion;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
    }
}

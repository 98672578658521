@import "../../variables";
@import "~bootstrap/scss/mixins/breakpoints";

.btn, button {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
  height: 44px;
  border-radius: 4px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg{
    margin-left: 20px;
    width: 20px;
    height: 20px;
  }
  &.btn-lg{
    font-size: 18px;
    line-height: 18px;
    height: 56px;
    svg{
      margin-left: 24px;
      width: 20px;
      height: 20px;
    }
  }
  &.btn-primary{
    border: none;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
    &.white {
      background-color: $color-white;
      border: none;
      color: $color-darkGreen;
      &:hover {
        background-color: $color-neutral10;
        color: $color-lightGreen;
      }
    }
    &.green {
      background-color: $color-darkGreen;
      border-color: transparent;
      color: $color-white;
      border: none;
      &.custom-paypal-btn {
        background-color: $color-paypal-button;
        color: $color-paypal-button;
        font-size: 1px;
        position: relative;

        &:active,
        &:focus,
        &:target,
        &:hover {
          background-color: $color-paypal-button;
        }

        .paypal-button-logo {
          transform: scale(2.5);
          width: 46px;
          height: 29px;
          position: absolute;
          top: 3px;
          left: 30%;

          @include media-breakpoint-down(md) {
            left: 40%;
          }
        }
      }
      &:hover {
        background-color: $color-lightGreen;
        border: none;
      }
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
    &:disabled {
      background-color: $color-neutral30 !important;
      color: $color-white !important;
      &:hover {
        background-color: $color-neutral30 !important;
        color: $color-white !important;
      }
    }
  }

  &.btn-outline{
    background-color: transparent;
    &.white {
      border: 2px solid $color-white;
      color: $color-white;
      &:hover {
        border-color: transparent;
      }
    }
    &.green {
      border: 2px solid $color-darkGreen;
      color: $color-darkGreen;
      &:hover {
        border-color: $color-lightGreen;
        color: $color-lightGreen;
      }
    }
    &:disabled {
      background-color: transparent;
      border: 2px solid $color-neutral30;
      color: $color-neutral30;
      &:hover {
        background-color: transparent;
        border-color: $color-neutral30;
        color: $color-neutral30;
      }
    }
  }
  // this class is custom since it was an specific requirement from the client
  // check https://www.figma.com/file/iMUnKZdlGmT6XsZ9CSMZOO/Natures-Menu?node-id=4704%3A3753
  &.btn-ghost {
    border: none;
    background-color: transparent;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
    &.white {
      color: $color-white;
      &:hover {
        color: $color-neutral40;
      }
    }
    &.green {
      color: $color-darkGreen;
      &:hover {
        color: $color-lightGreen;
        background-color: $color-neutral10;
      }
    }
    &:disabled {
      background-color: transparent;
      color: $color-neutral30;
      &:hover {
        background-color: transparent;
        color: $color-neutral30;
      }
    }
  }
}

.btn,
.btn-primary,
.btn-outline,
.btn-ghost,
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.show > .btn.dropdown-toggle {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

input[type="text"], input[type="number"], input[type="tel"], input[type="password"], input[type="email"], input[type="radio"], textarea {
  @extend %inputBase;
  @extend %inputValidationBase;
  &.form-control {
    border-radius: 4px !important;
  }
}
.input-group {
  &:disabled {
    [class^="icon"]{
      color: $color-neutral30 !important;
    }
  }
  [class^="icon"]{
    color: $color-neutral50 !important;
    position: absolute;
    right: 25px;
    top: 12px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    *{
      height: 100%;
    }
  }
}
.invalid-feedback {
  @extend %inputFeedbackBase;
  color: map-get($colors, "error");
}
.valid-feedback {
  @extend %inputFeedbackBase;
  color: map-get($colors, "lightGreen");
}
input:disabled[type="radio"] {
  background-color: map-get($colors, "neutral30") !important;
}

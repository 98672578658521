.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* CHECKBOX */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $color-white;
    border: 2px solid map-get($colors, "darkGreenRadio");
    border-radius: $border-radius-base;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: $color-white;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: map-get($colors, "darkGreenRadio");
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* disabled cases */

.checkbox-container input:checked {
    &:disabled {
        &~.checkmark {
            border: none;
            background-color: map-get($colors, "neutral30");

            &:after {
                left: 10px;
                top: 6px;
            }
        }
    }
}

.checkbox-container input:not(:checked) {
    &.is-invalid {
        &~.checkmark {
            border-color: map-get($colors, "error");
        }
    }

    &:disabled {
        &~.checkmark {
            border-color: map-get($colors, "neutral30");
        }
    }
}

/* RADIO */
.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
    position: absolute;
    top: 0;
    left: 1px;
    width: 24px;
    height: 24px;
    transform: translateY(-0.075em);
    background-color: $color-white;
    border-radius: 50%;
    border: 2px solid map-get($colors, "darkGreenRadio");

    &.disabled {
        border: 2px solid map-get($colors, "neutral30");

        &::after {
            background: map-get($colors, "neutral30") !important;
        }
    }
}

/* On mouse-over, add a grey background color */
.radio-container:hover input~.radio-checkmark {
    background-color: map-get($colors, "neutral30");
}

.radio-container:hover input:disabled~.radio-checkmark {
    background-color: $color-white;
}

.radio-container:hover input:disabled:checked~.radio-checkmark {
    background-color: transparent;
}


/* When the radio button is checked, add transparent background to the outer circle */

/* When the radio button is checked, add a darkGreen background to the dot */
.radio-container input:checked~.radio-checkmark {
    background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.radio-checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: map-get($colors, "darkGreenRadio");
}

/* disabled cases */

.radio-container input:checked {
    &:disabled {
        &~.radio-checkmark {
            border-color: map-get($colors, "neutral30");

            &:after {
                background: map-get($colors, "neutral30");
            }
        }
    }
}

.radio-container input:not(:checked) {
    &:disabled {
        &~.radio-checkmark {
            border-color: map-get($colors, "neutral30");
        }
    }
}


/* SWITCHES */

.form-check-toggle {
    position: relative;
    padding-left: 0;
    line-height: 24px;
    height: 24px;

    input {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 0%;
        margin: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    input+span {
        cursor: pointer;
        user-select: none;
        height: 20px;
        margin-left: 70px;
        display: block;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            display: inline-block;
            height: 24px;
            width: 50px;
            background: $color-neutral20;
            //box-shadow: inset 0 0 5px $engie-gray-10;
            transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
            border-radius: 15px;
        }

        &:after {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-left: 1px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            background: $color-white;
            transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            text-align: center;
            font-weight: bold;
            content: '';
            //border: solid $engie-black-01 0.05rem;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);
        }
    }

    input {
        &:checked+span {
            &:after {
                content: '';
                margin-left: 28px;
                box-shadow: none;
            }

            &:before {
                background-color: $color-black;
                border-color: $color-black;
                transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
            }
        }
    }
}
////////////////
// TYPOGRAPHY //
////////////////
$font-path: "../../../fonts/";
// FONT-FAMILY
// QUALION
$font-qualion: 'qualion';

$font-qualion-path: $font-path + "qualionRegular.otf";
$font-qualion-italic-path: $font-path + "qualionRegularItalic.otf";
$font-qualion-bold-path: $font-path + "qualionBold.otf";

// SIZE
$title-00--desktop: 16px;
$title-01--desktop: 18px;
$title-02--desktop: 24px;
$title-03--desktop: 28px;
$title-04--desktop: 32px;
$title-05--desktop: 40px;
$title-06--desktop: 56px;

$title-00--mobile: 12px;
$title-01--mobile: 14px;
$title-02--mobile: 24px;
$title-03--mobile: 28px;
$title-04--mobile: 32px;
$title-05--mobile: 40px;

$text-00--desktop: 12px;
$text-01--desktop: 14px;
$text-02--desktop: 16px;
$text-03--desktop: 18px;
$text-04--desktop: 20px;
$text-05--desktop: 24px;

$text-00--mobile: 12px;
$text-01--mobile: 14px;
$text-02--mobile: 16px;
$text-03--mobile: 18px;
$text-04--mobile: 20px;

////////////////
//// COLORS ////
////////////////

// Color map
$colors: (
  "darkGreen": #2D5843,
  "darkGreenRadio": #355C28,
  "darkGreen70": #00573F,
  "darkGreen80": #162515,
  "lightGreen": #3B7A5B,
  "subduedGreen" : #4A785D,
  "successGreen" : #9FD39E,
  "gold": #F5DB78,
  "gold20": #E9DDB0,
  "gold30": #E7D48F,
  "neutral": #80817C,
  "neutral5": #F5F0ED,
  "neutral10": #FCF9F4,
  "neutral20": #D8D5D1,
  "neutral30": #BAB9B4,
  "neutral50": #80817C,
  "neutral40": #EFEBE7,
  "neutral40v2": #9D9D98,
  "neutral40v3": #EFEBE766,
  "neutral60": #636560,
  "neutral70": #464944,
  "neutral80": #292E28,
  "neutral80v2": #575757,
  "black": #000,
  "info": #21A0CE,
  "info-blue": #9ED6EB,
  "error": #EE4B4B,
  "success": #07624A,
  "warn": #EF792C,
  "info-light": #F0F8FB,
  "error-light": #FEEDEA,
  "error-light2": #FCBDBD,
  "success-light": #ECF6EC,
  "success-light2": #9FD39E,
  "warn-light": #FDF3ED,
  "warn-orange": #FFCAA7,
  "disabled": #EFEBE7,
  "background": #E4DCD7,
  "white": #fff,
  "shadow": #ccc,
  "translucent-white": rgba(255, 255, 255, 0.6),
  "subscription-interval": rgba(255, 254, 239, 0.6),
  "disabledTabs": #787A74,
  "paypal-button": #FFD13B,
  "range-8020": #C1CB88,
  "range-8020-puppy": #9AC6E4,
  "range-mix": #F4C485,
  "secondary-green": #BFCC80,
  "secondary-pink": #E89CAE
);

// Color variables (please add the color to the map first and then get it so values are in sync)
$color-darkGreen: map-get($colors, "darkGreen");
$color-darkGreenRadio: map-get($colors, "darkGreenRadio");
$color-darkGreen70: map-get($colors, "darkGreen70");
$color-darkGreen80: map-get($colors, "darkGreen80");
$color-lightGreen: map-get($colors, "lightGreen");
$color-subduedGreen: map-get($colors, "subduedGreen");
$color-successGreen: map-get($colors, "successGreen");
$color-gold: map-get($colors, "gold");
$color-gold20: map-get($colors, "gold20");
$color-gold30: map-get($colors, "gold30");
$color-neutral: map-get($colors, "neutral");
$color-neutral5: map-get($colors, "neutral5");
$color-neutral10: map-get($colors, "neutral10");
$color-neutral20: map-get($colors, "neutral20");
$color-neutral30: map-get($colors, "neutral30");
$color-neutral40: map-get($colors, "neutral40");
$color-neutral40v2: map-get($colors, "neutral40v2");
$color-neutral40v3: map-get($colors, "neutral40v3");
$color-neutral50: map-get($colors, "neutral50");
$color-neutral60: map-get($colors, "neutral60");
$color-neutral70: map-get($colors, "neutral70");
$color-neutral80: map-get($colors, "neutral80");
$color-neutral80v2: map-get($colors, "neutral80v2");
$color-black: map-get($colors, "black");
$color-error: map-get($colors, "error");
$color-warn: map-get($colors, "warn");
$color-success: map-get($colors, "success");
$color-info: map-get($colors, "info");
$color-info-blue: map-get($colors, "info-blue");
$color-error-light: map-get($colors, "error-light");
$color-error-light2: map-get($colors, "error-light2");
$color-warn-light: map-get($colors, "warn-light");
$color-warn-orange: map-get($colors, "warn-orange");
$color-success-light: map-get($colors, "success-light");
$color-success-light2: map-get($colors, "success-light2");
$color-info-light: map-get($colors, "info-light");
$color-disabled: map-get($colors, "disabled");
$color-background: map-get($colors, "background");
$color-white: map-get($colors, "white");
$color-translucent-white: map-get($colors, "translucent-white");
$color-header-background: map-get($colors, "neutral10");
$color-shadow: map-get($colors, "shadow");
$color-subscription-interval: map-get($colors, 'subscription-interval');
$color-disabledTabs: map-get($colors, "disabledTabs");
$color-paypal-button: map-get($colors, "paypal-button");
$color-range-8020: map-get($colors, "range-8020");
$color-range-8020-puppy: map-get($colors, "range-8020-puppy");
$color-range-mix: map-get($colors, "range-mix");
$color-secondary-green: map-get($colors, "secondary-green");
$color-secondary-pink: map-get($colors, "secondary-pink");

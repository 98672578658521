%inputBase {
  border-radius: $border-radius-base;
  height: 44px;
  border: 1px solid map-get($colors, "neutral20");
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: map-get($colors, "neutral80");
  width: 100%;
}
%inputFeedbackBase {
  font-size: 14px;
  line-height: 20px;
}
%inputValidationBase{
  &.is-invalid{
    border-color: map-get($colors, "error");
  }
  &.is-valid{
    border-color: map-get($colors, "lightGreen");
  }
  &:disabled {
    background-color: map-get($colors, "disabled");
  }
  &:focus{
    border-color: map-get($colors, "neutral50");
    box-shadow: none !important;
  }
  &::placeholder{
    color: map-get($colors, "neutral30");
  }
  &:valid{
    background-image: none;
  }
}